<template>
    <div>
        <div class="card card-custom mt-5">
            <div class="card-body">
                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10">
                                <table class="table table-bordered">

                                    <tbody>
                                    <tr v-if="$can('report_purchase.suppliers_reports')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.suppliers_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'suppliers-reports.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_suppliers_balance')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.suppliers_balance_reports')}}</span>
                                            <div>
                                                <router-link :to="{name: 'suppliers-balance-reports.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_suppliers_purchase')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.supplier_purchase_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'suppliers-purchase-reports.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr  v-if="$can('report_purchase.report_supplier_transactions')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.supplier_transactions_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'supplier-transactions-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>

                                    <tr  v-if="$can('report_purchase.supplier_aged_ledger')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.supplier_aged_ledger')}}</span>
                                            <div>
                                                <router-link :to="{name: 'supplier-aged-ledger.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.paid_purchases')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.paid_purchases')}}</span>
                                            <div>
                                                <router-link :to="{name: 'paid-purchases-reports.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>

                                    <tr  v-if="$can('report_purchase.simple_supplier_balance')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.simple_suppliers_balance_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'simple-suppliers-balance-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="d-flex justify-content-between">
                                            <h4>{{$t('MENU.purchases_invoices_segmented_Reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_purchases_segmented')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.purchases_by_staff_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'purchases-segmented-reports.list', query:{tab: 'summary', 'filter':'user'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'purchases-segmented-reports.list', query:{tab: 'details', 'filter':'user'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_purchases_segmented')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.purchases_by_supplier_report')}}</span>
                                            <div>
                                                <div>
                                                    <router-link :to="{name: 'purchases-segmented-reports.list', query:{tab: 'summary', 'filter':'supplier'}}" target="_blank">
                                                        {{$t('summary')}}
                                                    </router-link>
                                                    <router-link :to="{name: 'purchases-segmented-reports.list', query:{tab: 'details', 'filter':'supplier'}}" target="_blank">
                                                        | {{$t('details')}}
                                                    </router-link>
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="d-flex justify-content-between">
                                            <h4>{{$t('MENU.product_purchase_report')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_product_purchases')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.product_purchases_report_by_product')}}</span>
                                            <div>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'summary', 'group_by':'product'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'details', 'group_by':'product'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.product_purchases_report_by_supplier')}}</span>
                                            <div>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'summary', 'group_by':'supplier'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'details', 'group_by':'supplier'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.product_purchases_report_by_employee')}}</span>
                                            <div>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'summary', 'group_by':'employee'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'product-purchases-reports.list', query:{tab: 'details', 'group_by':'employee'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="d-flex justify-content-between">
                                            <h4>{{$t('MENU.purchases_payments_reports')}}</h4>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_payments_purchases')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.daily_purchases_payments_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'summary', filter:'daily'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'details', filter:'daily'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.weekly_purchases_payments_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'summary', filter:'weekly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'details', filter:'weekly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.monthly_purchases_payments_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'summary', filter:'monthly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'details', filter:'monthly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.yearly_purchases_payments_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'summary', filter:'yearly'}}" target="_blank">
                                                    {{$t('summary')}}
                                                </router-link>
                                                <router-link :to="{name: 'payments-purchases-reports.list', query:{tab: 'details', filter:'yearly'}}" target="_blank">
                                                    | {{$t('details')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_purchase.report_payments_purchases')">
                                      <td class="d-flex justify-content-between">
                                        <span>{{ $t('MENU.bonds_and_payments_details_report') }}</span>
                                        <div>
                                          <router-link :to="{name: 'suppliers-bonds-payments-details.list'}" target="_blank">
                                            {{ $t('details') }}
                                          </router-link>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_reports")}]);
        },
    }
</script>
<style scoped>
    td {
        padding: 1rem;
    }
</style>
